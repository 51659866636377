.root {
    position: relative;
}
.main {
    width: 100%;
    min-height: calc(100vh - 268px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
}

.root > footer {
    margin-top: auto;
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root > footer {
        grid-area: footer;
    }
    .root > header {
        grid-area: header;
    }
    .main {
        grid-area: main;
        margin: 0;
        padding: 0;
        margin-top: 68px;
    }
    .navigation {
        grid-area: navigation;
        margin-top: 148px;
    }
    .navigationButton {
        grid-area: navigationButton;
    }

    .root {
        display: grid;
        grid-template-columns: 1fr 1040px 272px 1fr;
        grid-template-rows: auto;
        grid-column-gap: 41px;
        grid-template-areas:
            "header header header header"
            ". main navigation ."
            ". navigationButton . ."
            "footer footer footer footer";
    }
}
