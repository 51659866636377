.root {
    width: 100%;
    padding: 0;
    display: block;
}

.text {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin-bottom: 4px;
    margin-left: 3px;
}

.required {
    color: var(--error-400-base);
    font-weight: 700;
}

.errorWrapper {
    width: 100%;
    background-color: var(--error-400-base);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}

@media (min-width: 660px) {
    .text {
        grid-area: text;
        text-align: end;
        align-self: flex-start;
        margin-top: 7px;
    }
    .wrapper {
        grid-area: wrapper;
    }

    .errorWrapper {
        grid-area: errorWrapper;
    }

    .root {
        display: grid;
        grid-template-columns: 115px 1fr;
        grid-template-rows: auto;
        grid-column-gap: 9px;
        grid-template-areas:
            "text wrapper"
            ". errorWrapper";
        align-items: center;
        justify-content: end;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
