.root {
    position: relative;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    border-radius: 4px;
}

.root::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
}

.root::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
}

.root:active {
    background-color: var(--tertiary-300);
}

.root:active::after {
    background-color: var(--tertiary-300);
}

.root:active::before {
    background-color: var(--tertiary-300);
}
