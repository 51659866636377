.root {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: var(--black-500);
    margin: 0;
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
}

.icon {
    margin-left: 7px;
    width: 16px;
    height: 15px;
    object-fit: contain;
    fill: var(--tertiary-600);
}

.root:active {
    color: var(--black-300);
}

.root:active .icon {
    fill: var(--tertiary-300);
}

.root:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

@media (min-width: 660px) {
    .root {
        font-size: 18px;
        font-weight: bold;
    }

    .icon {
        width: 20px;
        height: 19px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
