.root {
    position: absolute;
    z-index: 10;
    top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 890px;
    margin: 0 auto;
    padding: 13px 0;
    background-color: var(--black-700);
}

.list {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

@media (min-width: 660px) {
    .root {
        position: relative;
        background-color: transparent;
        padding: 0 10px;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 65px;
    }

    .list {
        min-height: 45px;
        border-radius: 4px;
        border: solid 1px var(--black-300);
        background-color: var(--black-100);
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        width: 272px;
        padding: 0;
        margin: 0;
    }

    .list {
        flex-direction: column;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    /* .root::before {
        content: "";
        position: absolute;
        top: 26px;
        width: 100%;
        height: 100vh;
        display: block;
        z-index: 5;
        background-color: var(--black-40);
    } */
}
