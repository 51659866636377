.iframe {
    position: absolute;
    height: calc(100% - 56px);
    width: 100%;
    border: solid 5px var(--black-100);
    border-left-width: 1px;
    border-right-width: 1px;
}
.root {
    min-height: 900px;
}

.panel {
    width: 100%;
    height: 20px;
    background-color: #e3e3e3;
}

.h2 {
    margin: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    display: inline-block;
}

.button {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    color: var(--tertiary-800);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 27px;
    min-width: 60px;
    padding: 2px 6px;
    border: 2px solid var(--tertiary-600);
    margin: 0;
    cursor: pointer;
    outline: none;
    display: inline-flex;
    vertical-align: super;
}

.icon {
    width: 12px;
    height: 12px;
    margin: 0;
    margin-right: 2px;
    fill: var(--tertiary-800);
}

.button:active {
    color: var(--tertiary-300);
    border-color: var(--tertiary-300);
}

.button:active .icon {
    fill: var(--tertiary-300);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:disabled {
    cursor: auto;
    color: var(--black-300);
    border-color: var(--black-300);
    background-color: var(--whitebase);
}

.button:disabled .icon {
    fill: var(--black-300);
}

.buttonNext {
    border-color: var(--warning-200);
    background-color: var(--warning-200);
    padding-right: 3px;
    padding-left: 6px;
    letter-spacing: 0.3px;
}

.buttonNext .icon {
    transform: rotate(180deg);
    margin: 0;
    margin-left: 4px;
    margin-right: 2px;
}

@media (min-width: 660px) {
    .h2 {
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .iframe {
        height: calc(100% - 110px);
    }

    .h2 {
        margin-top: 59px;
        margin-bottom: 25px;
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .button {
        font-size: 10px;
    }
}
