:root {
    --whitebase: #ffffff;
    --whitebase-100: #ced5d8;

    --black-100: #ebebeb;
    --black-200: #cccccc;
    --black-300: #979797;

    --black-base: #444444;
    --black-500: #333333;
    --black-700: #141414;

    --primary-500: #1873cc;
    --primary-400-base: #1e90ff;
    --primary-200: #a5d3ff;

    --secondary-700: #330a1f;
    --secondary-600: #66153e;
    --secondary-400-base: #982729;
    --secondary-400-base-error: #b66769;
    --secondary-500: #b3246d;
    --secondary-300: #ff71b9;
    --secondary-200: #ffaed7;
    --secondary-100: #ffebf5;

    --tertiary-400-base: #5540fb;
    --tertiary-300: #8879fc;
    --tertiary-200: #bbb3fd;
    --tertiary-100: #ccc6fe;
    --tertiary-500: #3b2db0;
    --tertiary-600: #f4943b;
    --tertiary-700: #c9555e;
    --tertiary-800: #22697b;

    --success-100: #d1eedd;
    --success-200: #a3ddbc;
    --success-300: #5dc389;
    --success-400-base: #18a957;
    --success-500: #f4943b;
    --success-600: #0a4423;
    --success-700: #052211;

    --warning-100: #fff1d7;
    --warning-200: #ffcb29;
    --warning-300: #f4943b;
    --warning-400-base: #ff8a38;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #ff0000;
    --error-500: #9c0f2e;
    --error-600: #59091a;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --black-40: rgba(0, 0, 0, 0.4);
}

body,
html {
    margin: 0;
    font-family: "Roboto", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    justify-content: center;
    align-items: center;
}

.errorSwal {
    z-index: 99999;
}
