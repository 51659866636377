.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 9px;
    grid-template-areas:
        "currentEmployer currentEmployer"
        "position phone"
        "address address";
    align-items: center;
    justify-content: end;
}

.root {
    display: block;
    float: left;
    border: 1px solid #2f78b9;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    height: 43px;
}

.root label:hover {
    background: #ffffff;
    color: #000000;
    cursor: pointer;
}

.root label {
    color: var(--black-base);
    cursor: pointer;
    margin-top: 1rem;
    width: 100%;
    line-height: 40px;
    margin: 0;
    display: inherit;
    width: 100%;
}

.fileImg {
    margin-bottom: 15px;
}

.fileImg table {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.fileImg table:hover {
    background-color: #efefef;
}
.fileImg td:nth-child(1) {
    width: 80%;
}

.fileImg td:nth-child(2) {
    width: 10%;
}

.fileImg td:nth-child(3) {
    width: 10%;
    cursor: pointer;
}

.fileImg table {
    border: 1px solid #dee2e6;
}

.fileImg svg {
    width: 50px;
    height: 36px;
    margin-top: 4px;
    color: #3688be;
}

.fileImg table tr td a i {
    color: #3688be;
}

.fileImg table tr td i {
    color: var(--tertiary-700);
}
