.root {
    width: 100%;
    background-color: var(--black-700);
    min-height: 90px;
}

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1377px;
    padding: 18px 20px;
}

.text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
    width: 100%;
}

.schoolsContainer {
    display: flex;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .wrapper {
        max-width: 904px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .wrapper {
        padding-top: 24px;
        padding-bottom: 22px;
        max-width: 1337px;
    }

    .text {
        font-size: 14px;
    }
}

/* --mo4ile-widths-only */
@media (max-width: 659px) {
}
