.root {
    flex: 1;
}

.state {
    color: var(--tertiary-500);
}

a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

a > h4,
h4 {
    color: var(--tertiary-600);
}

.campus {
    padding-bottom: 10px;
}

.campus a {
    color: var(--whitebase-100);
}

.title {
    margin-bottom: 5px;
}
