.modalWrapper {
    z-index: 999;
    background: rgba(16, 14, 23, 0.5);
    height: 100%;
    width: 100%;
    display: block;
    padding: 0px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modalDialog {
    position: relative;
    margin: 1.75rem auto;
    max-width: 50vw;
    width: auto;
    pointer-events: none;
    /* opacity:0;
    transition: all 3s;
    opacity: 1; */
}

.modalContent {
    position: absolute;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    display: -ms-flexbox;
}

.modalHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    text-align: center;
    -ms-flex-align: start;
    -ms-flex-pack: justify;
}

.modalHeader h2 {
    width: 100%;
}

.modalBody {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    -ms-flex: 1 1 auto;
}

.modalFooter {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    -ms-flex-align: center;
    -ms-flex-pack: end;
}

.scroll {
    overflow-y: scroll;
    height: 30vh;
    padding: 0 5px;
    color: #444;
}

.agreements {
    margin: 20px;
    color: #333;
}

.agreements p {
    font-weight: 800;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btnPrimary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
